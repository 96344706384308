<template>
    <div class="collection">
        <vendor-top :type.sync="type" :searchKey.sync="searchKey" @submitSearch="submitSearch">
            <template slot="button">
                <el-button type="warning" size="small" @click="$router.push('/vendor/productEdit')">{{
                    $t('xin-zeng-chan-pin')
                }}</el-button>
                <el-button type="primary" size="small" @click="$router.push('/vendor/customCategory')">{{
                    $t('bian-ji-fen-lei')
                }}</el-button>
            </template>

            <template slot="bottom-left">
                <el-tabs size="small" v-model="customCateId">
                    <el-tab-pane name="0">
                        <span slot="label">{{ $t('quan-bu-chan-pin') }}</span>
                    </el-tab-pane>
                    <el-tab-pane :name="`${item.id}`" v-for="(item, index) in menus" :key="index">
                        <span slot="label">{{ getName(item) }}({{ item.productId.length }})</span>
                    </el-tab-pane>
                </el-tabs>
                <!-- <el-button :type="!customCateId ? 'warning' : 'info'" size="small" plain @click="customCateId = 0"
                    >全部产品</el-button
                >
                <el-button
                    @click="customCateId = item.id"
                    v-for="(item, index) in menus"
                    :type="customCateId === item.id ? 'warning' : 'info'"
                    size="small"
                    plain
                    >{{ getName(item) }}({{ item.productId.length }})</el-button
                > -->
            </template>

            <template slot="bottom-right">
                <el-radio-group v-model="status" size="mini">
                    <el-radio-button label="0">{{ $t('quan-bu') }}</el-radio-button>
                    <el-radio-button label="1">{{ $t('shang-jia-zhong') }}</el-radio-button>
                    <el-radio-button label="2">{{ $t('wei-shang-jia') }}</el-radio-button>
                </el-radio-group>
            </template>
        </vendor-top>

        <div class="el-main" v-loading="loading">
            <div class="mainList" ref="list">
                <el-row :gutter="30" type="flex">
                    <el-col v-for="item in list" :key="item.id">
                        <product-gride
                            @refreash="getData"
                            :info="item"
                            :isNew="item.isNew"
                            shadow
                            vendorPage
                        ></product-gride>
                    </el-col>
                </el-row>
            </div>
            <empty-page v-if="empty">
                <img slot="img" :src="require('@/assets/kong_img_default.png')" />
                <span slot="text">{{ $t('zan-shi-mei-you-chan-pino') }}</span>
            </empty-page>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableList from '../../mixins/pageableList';
import vendorTop from '../../components/vendor/Top.vue';
import ProductGride from '../../components/product/Gride.vue';
import BrandGride from '../../components/brand/Gride';
import { collectType } from '../../utils/AppState';

export default {
    name: 'product',
    mixins: [pageableList],
    data() {
        return {
            url: '/product/my',
            httpType: 'get',
            type: 'PRODUCT',
            chooseList: [],
            isEdit: false,
            menus: [],
            customCateId: '',
            status: 0,
            noPage: true
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        listQuery() {
            if (this.customCateId) {
                return {
                    vendorId: this.buyersInfo.vendorId,
                    customCateId: Number(this.customCateId || 0) || '',
                    status: this.status
                };
            } else {
                return {
                    vendorId: this.buyersInfo.vendorId,
                    status: this.status
                };
            }
        },
        typeName() {
            return collectType.get(this.type);
        }
    },
    watch: {
        listQuery() {
            this.page = 1;
            this.getData();
        },
        type() {
            this.isEdit = false;
            this.chooseList = [];
        },
        customCateId() {
            this.status = 0;
        }
    },
    mounted() {
        this.getData();
        this.getSelect();
    },
    methods: {
        brantch() {
            this.$http
                .post('/collect/batchCancel', {
                    type: this.type,
                    ids: this.chooseList.join(',')
                })
                .then(res => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        getSelect() {
            this.$http
                .get('/customCategory/my', {
                    vendorId: this.buyersInfo.vendorId
                })
                .then(res => {
                    this.menus = res;
                    this.loading = false;
                });
        }
    },
    components: {
        vendorTop,
        ProductGride,
        BrandGride
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.route {
    background-color: transparent !important;
}

.el-main {
    margin: 20px 0 0;
    // min-height: calc(100% - 136px);
    overflow: hidden;
}
</style>
<style lang="scss">
.el-radio-group {
    .el-radio-button__inner {
        border-radius: 0px !important;
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #ffa526;
        border-color: #ffa526;
        box-shadow: -1px 0 0 0 #ffa526;
    }
}
</style>
